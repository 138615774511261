// import { StyleSheet, Text, View } from 'react-native';
import React from 'react';
import { Link } from 'react-router-dom';

import ContactIcons from '../Contact/ContactIcons';

const { PUBLIC_URL } = process.env; // set automatically from package.json:homepage

// const styles = StyleSheet.create({
//   textContainer: {
//     flex: 1,
//   },
//   text: {
//     fontSize: 18,
//     fontFamily: 'Times New Roman',
//     textAlign: 'justify',
//     lineHeight: 30,
//   },
// });

const SideBar = () => (
  <section id="sidebar">
    <section id="intro">
      <Link to="/" className="logo">
        <img src={`${PUBLIC_URL}/images/moi.jpg`} alt="" />
      </Link>
      <header>
        <h2>Bappa Muktar</h2>
        <p>
          <a href="mailto:bappamuktar@gmail.com">bappamuktar@gmail.com</a>
        </p>
      </header>
    </section>

    <section className="blurb">
      <h2>About</h2>
      {/* <p>Hi, I&apos;m Michael. I like building things.
        I am a <a href="https://icme.stanford.edu/">Stanford ICME</a> graduate, YC Alumni, and
        the co-founder and CTO of <a href="https://arthena.com">Arthena</a>. Before Arthena I was
        at <a href="https://matroid.com">Matroid</a>
        , <a href="https://planet.com">Planet</a>
        , <a href="https://planetaryresources.com">Planetary Resources</a>
        , <a href="https://facebook.com">Facebook</a>
        , and <a href="https://seds.org">SEDS</a>.
      </p> */}

      {/* <View style={styles.textContainer}>
        <Text style={styles.text}>
          Hi, my name is Muktar Bappa. I am a Ph.D. graduate in Information Science and Technology
          from the <a href="https://uqo.ca/"> University of Quebec in Outaouais (UQO) </a>. My research
          interests include VANET routing, autonomous self-driving, and artificial intelligence.
          I am currently a programmer analyst at <a href="https://www.exfo.com/">EXFO</a> Montreal.
        </Text>
      </View> */}

      <p style={{ textAlign: 'justify' }}>
        Hi, my name is Bappa Muktar. I am a Ph.D. graduate in Information
        Science and Technology from the{' '}
        <a href="https://uqo.ca/">University of Quebec in Outaouais (UQO)</a>.
        My research interests include VANET routing, autonomous self-driving,
        and artificial intelligence. I am currently a software developer at{' '}
        <a href="https://numetrix.ca/fr/accueil/">Numetrix Technologies</a> in
        Montreal.
      </p>

      <ul className="actions">
        <li>
          {!window.location.pathname.includes('/resume') ? (
            <Link to="/resume" className="button">
              Learn More
            </Link>
          ) : (
            <Link to="/about" className="button">
              About Me
            </Link>
          )}
        </li>
      </ul>
    </section>

    <section id="footer">
      <ContactIcons />
      <p className="copyright">
        &copy; Bappa Muktar <Link to="/">bmuktar.com</Link>.
      </p>
    </section>
  </section>
);

export default SideBar;
